import { useEffect, useState } from "react";
import {Paper, Typography, Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CustomAlert from '../components/alert';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddPart({token}){
    const statuses = [ "ordered",
        "in transit",
        "arrived",
        "in stock",
        "repositioned",
        "delivered",
    ];
    const [locations, setLocations] = useState([]);
    const[loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({
        spare_id: false,
        location_id: false,
        imo: false,
        ship_name: false,
        description: false,
        act_kg: false,
        currency_symbol: false,
        purchase_cost: false,
        other_cost: false,
        status: false,
        received: false,
        dispatched: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        spare_id: '',
        location_id: '',
        imo: '',
        ship_name: '',
        description: '',
        act_kg: '',
        currency_symbol: '',
        purchase_cost: '',
        other_cost: '',
        status: '',
        received: '',
        dispatched: '',


    });
    const [successMessage, setSuccessMessage] = useState('');
    const[ success, setSuccess] = useState(false);
    const [data, setData] = useState({
        spare_id: "",
        location_id: "",
        imo: "",
        ship_name: "",
        description: "",
        act_kg: "",
        currency_symbol: "",
        purchase_cost: "",
        other_cost: "",
        status: "",
        received: "",
        dispatched: "",
    })

    const handelSubmit = () => {
        // remove spare_id from data object
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spare/${data.spare_id}/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(data)
            }
        )
        .then(response => response.json())
        .then(data => {
                setSuccess(true);
                setSuccessMessage('Spare added successfully');
            }
        )
        .catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        fetch('https://port-dynamics.com/api/v1/logistics_manager/locations/?d=y', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_id}
            })

            setLocations(newData);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    ,[token])
    


    return (
        <Grid container spacing={2} sx={{marginBottom:'20px'}}>
            <Grid item xs={12}>
                    {loading ? <CircularProgress /> :
                         <>
                            <Paper style={{ padding: '10px', marginTop:'2%'}}>
                                {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                                <Typography variant="h4" component="h1">Add a new spare</Typography>
                                <TextField
                                    required
                                    id="spare_id"
                                    label="ID"
                                    error={errors.spare_id}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, spare_id: true})
                                            setErrorMessages({...errorMessages, spare_id: 'id must be a number'})
                                        }else{
                                            setData({...data,spare_id: event.target.value})
                                            setErrors({...errors,spare_id: false})
                                            setErrorMessages({...errorMessages, spare_id: ''})
                                        }
                                    }}
                                    value={data.spare_id}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.spare_id}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={locations}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        if(isNaN(newValue.id)){
                                            setErrors({...errors, location_id: true})
                                            setErrorMessages({...errorMessages,location_id: 'location must be a number'})
                                        }else{
                                            setData({...data, location_id: newValue.id})
                                            setErrors({...errors,location_id: false})
                                            setErrorMessages({...errorMessages, location_id: ''})
                                        }
                                    }}
                                    renderInput={params =>   
                                        <TextField
                                            {...params}
                                            label="Select Location"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                    style={{width:'100%'}}
                                />
                                <TextField
                                    required
                                    id="imo"
                                    label="imo"
                                    error={errors.imo}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, imo: true})
                                            setErrorMessages({...errorMessages, imo: 'imo must be a number'})
                                        }else{
                                            setData({...data,imo: event.target.value})
                                            setErrors({...errors,imo: false})
                                            setErrorMessages({...errorMessages, imo: ''})
                                        }
                                    }}
                                    value={data.imo}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.imo}
                                />
                                <TextField
                                    required
                                    id="ship_name"
                                    label="Ship name"
                                    onChange={event => setData({...data,ship_name: event.target.value})}
                                    value={data.ship_name}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="description"
                                    label="Description"
                                    onChange={event => setData({...data,description: event.target.value})}
                                    value={data.description}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="act_kg"
                                    label="Actual weight"
                                    error={errors.act_kg}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, act_kg: true})
                                            setErrorMessages({...errorMessages, act_kg: 'kg must be a number'})
                                        }else{
                                            setData({...data,act_kg: event.target.value})
                                            setErrors({...errors, act_kg: false})
                                            setErrorMessages({...errorMessages,act_kg: ''})
                                        }
                                    }}
                                    value={data.act_kg}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.act_kg}
                                />
                                <TextField
                                    required
                                    id="currency_symbol"
                                    label="Currency symbol"
                                    onChange={event => setData({...data,currency_symbol: event.target.value})}
                                    value={data.currency_symbol}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="purchase_cost"
                                    label="Purchase cost"
                                    error={errors.purchase_cost}
                                    onChange={event => {
                                        if(isNaN(event.target.value)){
                                            setErrors({...errors, purchase_cost: true})
                                            setErrorMessages({...errorMessages, purchase_cost: 'cost must be a number'})
                                        }else{
                                            setData({...data,purchase_cost: event.target.value})
                                            setErrors({...errors, purchase_cost: false})
                                            setErrorMessages({...errorMessages, purchase_cost: ''})
                                        }
                                    }}
                                    value={data.purchase_cost}
                                    fullWidth
                                    margin='normal'
                                    helperText={errorMessages.purchase_cost}
                                />
                                <TextField
                                    required
                                    id="other_cost"
                                    label="Other cost"
                                    onChange={event => setData({...data,other_cost: event.target.value})}
                                    value={data.other_cost}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="received"
                                    label="Received"
                                    onChange={event => setData({...data,received: event.target.value})}
                                    value={data.received}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="dispatched"
                                    label="Dispatched"
                                    onChange={event => setData({...data,dispatched: event.target.value})}
                                    value={data.dispatched}
                                    fullWidth
                                    margin='normal'
                                />
                                <TextField
                                    required
                                    id="status"
                                    label="Status"
                                    select
                                    value={data.status}
                                    fullWidth
                                    margin='normal'
                                    onChange={event => setData({...data,status: event.target.value})}
                                >
                                {statuses.map((status, index) => (
                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                ))}
                                </TextField>
                            </Paper>
                            <Button variant="contained" sx={{marginTop:'5px'}} color='primary' onClick={handelSubmit}>Submit</Button>
                        </>}
            </Grid>
        </Grid>
    )
}