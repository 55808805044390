
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Nav from './components/nav';
import Home from './pages/home';
import Locations from './pages/locations';
import Part from './pages/part';
import Parts from './pages/parts';
import Network from './pages/network';
import AddPart from './pages/addPart';
import Schedule from './pages/schedule';
import Fleet from './pages/fleet';
import Login from './pages/login';
import Consolidations from './pages/consolidations';
import {BrowserRouter, Routes, Route} from "react-router-dom";


const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    primary: {
      main: '#284db7',
    },
    secondary: {
      main: '#612785',
    },
    
  },
});

function App() {
  let token = undefined;
  let loggedIn = false
  if(localStorage.getItem('token')){
    token = localStorage.getItem('token');
    loggedIn = true;
  }else{
    loggedIn = false;
  }
  return (
    <ThemeProvider theme={theme}>
      <Nav loggedIn={loggedIn}/>
      <Container sx={{
        maxWidth: {
          xs: '95%',   // Full width on extra small screens
          sm: '95%',   // Full width on small screens
          md: '95%',    // 90% width on medium screens
          lg: '95%',    // 80% width on large screens
          xl: '95%',    // 70% width on extra large screens
        },
        margin: 'auto', // Center the container
      }}>
         <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/locations" element={loggedIn?<Locations token={token}/>:<Login/>} />
            <Route path="/network" element={loggedIn?<Network token={token}/>:<Login/>} />
            <Route path="/fleet/" element={loggedIn?<Fleet token={token}/>:<Login/>} />
            <Route path="/consolidations/" element={loggedIn?<Consolidations token={token}/>:<Login/>} />
            <Route path="/part/:id" element={loggedIn?<Part token={token}/>:<Login/>} />
            <Route path="/parts/" element={loggedIn?<Parts token={token}/>:<Login/>} />
            <Route path="/part/add" element={loggedIn?<AddPart token={token}/>:<Login/>} />
            <Route path="/schedule/:imo" element={loggedIn?<Schedule token={token}/>:<Login/>} />
            <Route path="/login" element={<Login />} />
          </Routes>
         </BrowserRouter>
     
      </Container>
    </ThemeProvider>
  );
}

export default App;