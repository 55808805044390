import React, { useEffect, useState } from "react";
import {Typography, Grid, Paper, Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SearchIcon from '@mui/icons-material/Search';

export default function Fleet({token}){
    const [fleet, setFleet] = useState([]);
    const [oldFleet, setOldFleet] = useState([]);
    const[loading, setLoading] = useState(true);
    const filterFleet = (e) => {
        if(!e.target.value){
            setFleet(oldFleet);
            return
        }
        const filteredFleet = fleet.filter(vessel => vessel.imo.toString().includes(e.target.value));
        if(!filteredFleet.length){
            setFleet([oldFleet]);
        }
        setFleet(filteredFleet);
    }
    useEffect(() => {
        fetch('https://port-dynamics.com/api/v1/logistics_manager/fleet/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setFleet(data);
            setOldFleet(data);
            setLoading(false);
        })
    }
    , [token]);
    return (
        <Grid container spacing={2} sx={{marginTop:'10px'}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel htmlFor="standard-adornment-amount">Search by imo</InputLabel>
            <Input
                id="standard-adornment-amount"
                type="text"
                startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                fullWidth
                onChange={filterFleet}
                color="secondary"
            />
            </Grid>
            {loading ? 
             <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
                <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
                </Grid>
            </Grid>
            :
                fleet.length?fleet.map((vessel, index) => {
                    return (
                        <Grid item xs={12} s={12} md={4} lg={4} key={index}>
                            <Paper sx={{padding: '10px'}}>
                                <DirectionsBoatIcon fontSize="small" color="secondary" />
                                <Typography variant="h3">IMO: {vessel.imo}</Typography>
                                <Grid container>
                                     <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography variant="body1">Name: {vessel.ship_name}</Typography>
                                        <Typography variant="body1">Customer name: {vessel.customer_name}</Typography>
                                        <Typography variant="body1">Supervisor: {vessel.supervisor}</Typography>
                                        <Typography variant="body1">Purchasing: {vessel.purchasing}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography variant="body1">Logistics: {vessel.logistics}</Typography>
                                        <Typography variant="body1">Start date: {vessel.start_date}</Typography>
                                        <Typography variant="body1">End date: {vessel.end_date}</Typography>
                                    </Grid>
                                </Grid>
                                <Button variant="contained" size="small" color="primary" href={`/schedule/${vessel.imo}`}>View schedule</Button>
                            </Paper>
                        </Grid>
                    )
                })
            
            
           :null }
        </Grid>
    )
}