import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from "react";
import {Typography, Grid, Box, Divider} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CustomAlert from '../components/alert';


export default function Schedule({token}){
  const {imo} = useParams();
  const [schedule, setSchedule] = useState([]);
  const [ports , setPorts] = useState([]);
  const [addSchedule, setAddSchedule] = useState(false);
  const [error , setError] = useState({
    port_error: false,
    ata_eta_error: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    port_error: '',
    ata_eta_error: '',
  });
  const [newSchedule, setNewSchedule] = useState({
    port_id: '',
    ata_eta: '',
  });
  const [updateError, setUpdateError] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const[ success, setSuccess] = useState(false);
  const[updateFormError, setUpdateFormError] = useState(false);
  const[updateFormErrorMessage, setUpdateFormErrorMessage] = useState('');

  const updateSchedule = (n, label, value) => {
    console.log(n, label, value);
    const oldSchedule = schedule;
    const updatedSchedule = oldSchedule.map(item => {
        if(item.n === n){
            const newItem = {
                ...item,
                [label]: value
            }
            return newItem;
        }else{
            return item;
        }
    })
    setSchedule(updatedSchedule);
  }
  const scheduleForm = (n, label, value, disabled) => {
    const labelMapping = {
        'actual time of arrivial/ estimated time of arrival': 'ata_eta',
    }
    return (
            <TextField 
                id={`${n}-${label}`}
                label={label}
                variant="outlined" 
                defaultValue={value} 
                disabled={label !== 'actual time of arrivial/ estimated time of arrival'?true:disabled}
                sx={{marginTop: '10px', minWidth: '100%'}}
                value={schedule.filter(item => item.n === n)[0][label]}
                error={!disabled?updateError: false}
                helperText={!disabled?updateErrorMessage:''}
                onChange={e => {
                    if(e.target.value === ''){
                        setUpdateError(true);
                        setUpdateErrorMessage(`${label} is required`);
                        return;
                    }
                    updateSchedule(n, labelMapping[label], e.target.value)
                }}
            />
    )
  }

  const createNewSchedule = () => {
    setSuccessMessage('');
    setSuccess(false);
    setError({
        port_error: false,
        ata_eta_error: false,
    });
    if(newSchedule.port_id === ''){
        setError({
            port_error: true,
        });
        setErrorMessage({
            port_error: 'Port is required',
        });
        return;
    }
    fetch(
        `https://port-dynamics.com/api/v1/logistics_manager/schedule/${imo}/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(newSchedule)
        }
    )
    .then(response => {
        if(response.status === 400){
            return response.text().then((text) => {
                setError({
                    ata_eta_error: true,
                });
                setErrorMessage({
                    ata_eta_error: text,
                });  // Set the error message returned by the server
                throw new Error(text);  // Throw error to catch block
            });
        }
        return response.json()
    })
    .then(data => {
        // remove old schedule
        setSchedule([]);
        setSchedule(data);
        setSuccess(true);
        setSuccessMessage('New schedule added successfully');
    })
    .catch(error => {
        console.error(error);
    });
  }
  const editSchedule = (n) => {
    const selectedSchedule = schedule.filter(item => item.n === n)[0];
    fetch(
        `https://port-dynamics.com/api/v1/logistics_manager/schedule/${imo}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(selectedSchedule)
            }
        )
        .then(response => {
            if(response.status === 400){
                console.log(response);
                return response.text().then((text) => {
                    setUpdateFormError(true);
                    setUpdateFormErrorMessage(text);  // Set the error message returned by the server
                    throw new Error(text);  // Throw error to catch block
                });
            }
            return response.json()
        })
        .then(data => {
            setSchedule(data);
        })
        .catch(error => {
            console.error(error);
      });
    }
  useEffect(() => {
    Promise.all([
        fetch(`https://port-dynamics.com/ports/list/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
       ,
        fetch(`https://port-dynamics.com/api/v1/logistics_manager/schedule/${imo}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        
    ])
    .then(([portsResponse, scheduleResponse]) => {
        return Promise.all([portsResponse.json(), scheduleResponse.json()])
    })
    .then(([portsData, scheduleData]) => {
        const ports = portsData.map(port => {
            return {
                id: port.port_id,
                label: `${port.name}-${port.UN_code}`
            }
        })
        const formattedSchedule = scheduleData.map((item, index) => {
            return {
                n: index,
                port_id: item.port_id,
                ata_eta: item.ata_eta,
                src: item.src,
                port_name: item.port_name,
                edit: item.src==='user'? false: true
            }
        })
        setPorts(ports);
        setSchedule(formattedSchedule);
    })
    .catch(error => {
        console.error(error);
    });
  }, [imo, token]);


  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => `${option.label}-${option.UN_code}`,
  });
  return (
   <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item xs={12} >
            {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color='primary' sx={{marginTop:'2px', marginLeft:'2px'}} onClick={e => {setAddSchedule(true)}}>Add new schedule</Button>
            </Grid>
            <Grid item xs={12}>
            {addSchedule? (
                <>
                    <Typography variant="body2" sx={{marginTop: '10px'}}>New schedule</Typography>
                    <Grid item xs={12} sx={{marginTop:'10px'}}>
                        <Autocomplete
                            disablePortal
                            id="ports"
                            options={ports}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option.label}
                            onChange={(option, value) => {newSchedule.port_id = value.id}}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Select a port"
                                    variant="outlined"
                                    fullWidth
                                    error={error.port_error}
                                    helperText={errorMessage.port_error}
                                />
                            }
                            filterOptions={filterOptions}
                            style={{width:'100%'}}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop:'10px'}}>
                    <TextField 
                            id='ata_eta'
                            label="actual time of arrivial/ estimated time of arrival"
                            variant="outlined"
                            fullWidth
                            value={newSchedule.ata_eta}
                            onChange={e => {setNewSchedule({...newSchedule, ata_eta: e.target.value})}}
                            error={error.ata_eta_error}
                            helperText={errorMessage.ata_eta_error}
                    />
                    </Grid>
                    <Button variant="contained" color='primary' sx={{marginTop: '10px'}} onClick={e => {createNewSchedule()}}>Save</Button>
                    <Divider sx={{marginTop: '10px'}}/>
                </>

            ): null}
            </Grid>
            <Grid item xs={12} >
            {updateFormError ? <CustomAlert error message={updateFormErrorMessage} disappear={true}/>:null}
            </Grid>
            {schedule.map((item, index) => {
                return (
                    <Grid item xs={12} key={`${item.n}-grid`}>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="body2" sx={{marginTop: '10px'}}>{`Schedule ${item.n+1}`}</Typography>
                            {!item.edit?
                               <Autocomplete
                                        disablePortal
                                        id="edit-ports"
                                        options={ports}
                                        sx={{ width: 300, marginTop: '10px'}}
                                        getOptionLabel={(option) => option.label}
                                        value={ports.filter(port => port.id === item.port_id)[0]}
                                        onChange={(event, value) => {
                                            if (value) {
                                              // Ensure `value` exists before accessing its `id`
                                              updateSchedule(item.n, 'port_id', value.id);
                                            }
                                        }}
                                        renderInput={params =>  {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label="Update port"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={item.edit}
                                                />
                                            )
                                        }
                    
                                            
                                    }
                                    filterOptions={filterOptions}
                                    style={{width:'100%'}}
                                />
                            
                            
                            :null}
                            <Grid item xs={12}>
                                {scheduleForm(item.n, "actual time of arrivial/ estimated time of arrival", item.ata_eta, item.edit)}
                            </Grid>
                            <Grid item xs={12}>
                                {scheduleForm(item.n, "Source", item.src, item.edit)}
                            </Grid>
                            <Grid item xs={12}>
                                {scheduleForm(item.n, "Portname", item.port_name, item.edit)}
                            </Grid>
                            {item.src==='user'?<Button variant="contained" color='primary' sx={{marginTop: '10px'}} onClick={e => {editSchedule(item.n)}}>Save</Button>:null}
                        </Box>
                        <Divider sx={{marginTop: '10px'}}/>
                    </Grid>
                )
            })}
    </Grid>
  )
}