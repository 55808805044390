import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import {Paper, Typography, Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CustomAlert from '../components/alert';


export default function Part({token}){
    const statuses = [ "ordered",
        "in transit",
        "arrived",
        "in stock",
        "repositioned",
        "delivered",
    ];
    const {id} = useParams();
    const [spare, setSpare] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const[ success, setSuccess] = useState(false);
    const[fieldValid, setFieldValid] = useState(false);
    const updateStatus = () => {
        setSuccess(false);
        setError(false);
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spare/${id}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(spare)
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpare(data);
                setLoading(false);
                setSuccess(true);
                setSuccessMessage('Spare updated successfully');
            }
        )
        .catch(error => {
            setFieldValid({
                imo: {
                    valid: false,
                    message:error.message,
                    color: 'warning',
                },
                status: {
                    valid: false,
                    message:error.message,
                    color: 'warning',
                },
            });
            setError(true);
            setErrorMessage(error.message);
            setLoading(false);
        });
    }
    useEffect(() => {
        setFieldValid({
            imo: {
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            status: {
                valid: true,
                error: '',
                color: '',
                message:'Please select status',
            },
        });
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spare/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpare(data);
                setLoading(false);
            }
        )
        .catch(error => {
            setError(true);
            setErrorMessage(error.message);
            setLoading(false);
        });
    }, [id, token]);


    return (
        <Grid item xs={12} style={{ marginTop: '5px', marginBottom:'1%' }}>
            {loading ? (<CircularProgress />) : (
                <>
                <Paper style={{ padding: '10px', marginTop:'2%'}}>
                    {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                    {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}
                    <Typography variant="h4" component="h1">Spare details</Typography>
                     <TextField
                        required
                        id="description"
                        inputProps={{readOnly:true}}
                        label="Description"
                        value={spare.description}
                        fullWidth
                        margin='normal'
                
                        />
                     <TextField
                        id="imo"
                        label="Imo"
                        onChange={event => setSpare({...spare,imo: event.target.value})}
                        value={spare.imo}
                        error={!fieldValid.imo.valid}
                        color={fieldValid.imo.color}
                        fullWidth
                        margin='normal'
                        focused={!fieldValid.imo.valid}
                        />
                    <TextField
                        required
                        id="weight"
                        inputProps={{readOnly:true}}
                        label="Weight in kgs"
                        type='number'
                        value={spare.act_kg}
                        fullWidth
                        margin='normal'
 
                        />
                    <TextField
                        required
                        inputProps={{readOnly:true}}
                        id="chargeable_kg"
                        label="Weight chargeable"
                        value={spare.chargeable_kg}
                        fullWidth
                        margin='normal'
               
                        />
                    <TextField
                        required
                        id="purchase_cost"
                        label="Purchase cost"
                        fullWidth
                        margin='normal'
                        />
                    <TextField
                        required
                        inputProps={{readOnly:true}}
                        id="other_cost"
                        label="Other cost"
                        value={spare.other_cost}
                        fullWidth
                        margin='normal'
                        />
                    <TextField
                        required
                        inputProps={{readOnly:true}}
                        id="currency_symbol"
                        label="Currency"
                        value={spare.currency_symbol}
                        fullWidth
                        margin='normal'
                        />
                    <TextField
                        required
                        id="received"
                        inputProps={{readOnly:true}}
                        label="Received on"
                        value={spare.received}
                        fullWidth
                        margin='normal'
                        />
                    <TextField
                        required
                        inputProps={{readOnly:true}}
                        id="dispatched"
                        label="Dispatched on"
                        value={spare.dispatched}
                        fullWidth
                        margin='normal'
                        />
                     <TextField
                        required
                        id="status"
                        label="Status"
                        error={!fieldValid.status.valid}
                        color={fieldValid.status.color}
                        select
                        value={spare.status}
                        fullWidth
                        margin='normal'
                        helperText={fieldValid.status.message}
                        onChange={event => setSpare({...spare, status: event.target.value})}
                        focused={!fieldValid.status.valid}
                        >
                        {statuses.map((status, index) => (
                            <MenuItem key={index} value={status}>{status}</MenuItem>
                        ))}
                    </TextField>
                </Paper>
                    <Button variant="contained" color="primary" style={{marginTop:'10px'}} onClick={updateStatus}>Update</Button>
                </>
            )}
        </Grid>
    )
}