import React, { useState, useEffect} from 'react';
import Alert from '@mui/material/Alert';


export default function CustomAlert({error, warning, info, success, disappear, message}){
    const [errorState, setErrorState] = useState(null);
    const [warningState, setWarningState] = useState(null);
    const [infoState, setInfoState] = useState(null);
    const [successState, setSuccessState] = useState(null);
    const [showError, setShowError] = useState(false);
    function setTimeout(callback, delay) {
        var date = Date.now();
        var timer;
        timer = setInterval(function() {
            if (Date.now() - date >= delay) {
                clearInterval(timer);
                callback();
            }
        }, 100);
        return
    }

    setTimeout(() => {
        console.log('disappear', disappear);
        if(disappear){
            setShowError(false);
        }
    }, 2000);

    useEffect(() => {
        if(error){
            setErrorState(true);
            setShowError(true);
        }
        if(warning){
            setWarningState(true);
            setShowError(true);
        }
        if(info){
            setInfoState(true);
            setShowError(true);
        }
        if(success){
            setSuccessState(true);
            setShowError(true);
        }
    }, [error, warning, info, success]);
    return (
        <div>
            {errorState && showError?<Alert severity="error">{message}</Alert>:null}
            {warningState && showError?<Alert severity="warning">{message}</Alert>:null}
            {infoState && showError?<Alert severity="info">{message}</Alert>:null}
            {successState && showError?<Alert severity="success">{message}</Alert>:null}
        </div>
    );
}

