import React from 'react';
import {Typography, Box, Button} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LayersIcon from '@mui/icons-material/Layers';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';





export default function Home(){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isxsScreen = useMediaQuery(theme.breakpoints.down('xs'));


 

    return (
        <>
          <Box sx={{display: 'flex', minWidth:'100%',backgroundColor:'white', marginTop:'10px'}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={8} sx={{padding:'10px'}}>
                        {isSmallScreen || isxsScreen? null : (
                          <Box sx={{ 
                            minWidth: '100%', 
                            backgroundImage: 'url(/logistics.png)', 
                            backgroundSize: 'cover', 
                            backgroundRepeat: 'no-repeat', 
                            height: '450px',
                            display: 'flex',
                          }}/>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}  lg={4} sx={{padding:'10px'}}>
                              <List sx={{maxWidth: '100%'}}>
                                  <ListItem >
                                    <ListItemText sx={{textAlign:'center'}}
                                      primary= {
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline', color:'black'}}
                                            component="span"
                                            variant="h2"
                                            color="text.primary"
                                          >
                                            Why Logistics Manager?
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                  <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                          <ListItemIcon>
                                            <LayersIcon color='secondary'/>
                                          </ListItemIcon>
                                        </ListItemAvatar>
                                        <ListItemText sx={{textAlign:'left'}}
                                          primary= ""
                                        
                                          secondary={
                                            <React.Fragment>
                                              <Typography
                                                sx={{ display: 'inline', color:'black'}}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                              >
                                                The provision of spares and supplies to vessel fleets is a complex process involving a plethora of organizations across multiple countires.
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                          <ListItemIcon>
                                            <AppsIcon color='secondary'/>
                                          </ListItemIcon>
                                        </ListItemAvatar>
                                        <ListItemText sx={{textAlign:'left'}}
                                          primary= ""
                                          secondary={
                                            <React.Fragment>
                                              <Typography
                                                sx={{ display: 'inline', color:'black'}}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                              >
                                                Nisomar's Logistics Manager shares key information across all the relevant stakeholders with the minimum of administration.
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                          <ListItemIcon>
                                            <SettingsIcon color='secondary'/>
                                          </ListItemIcon>
                                        </ListItemAvatar>
                                        <ListItemText sx={{textAlign:'left'}}
                                          primary= ""
                                          secondary={
                                            <React.Fragment>
                                              <Typography
                                                sx={{ display: 'inline', color:'black'}}
                                                component="span"
                                                variant="body1"
                                                color="text.primary"
                                              >
                                                The system is accessible from any device and provides APIs to update ERPs and other business systems.
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        />
                                      </ListItem>
                                      <ListItem alignItems="flex-start" >
                                      <ListItemAvatar>
                                        <ListItemIcon>
                                          <NotificationsIcon color='secondary'/>
                                        </ListItemIcon>
                                      </ListItemAvatar>
                                      <ListItemText sx={{textAlign:'left'}}
                                        primary= ""
                                        secondary={
                                          <React.Fragment>
                                            <Typography
                                              sx={{ display: 'inline', color:'black'}}
                                              component="span"
                                              variant="body1"
                                              color="text.primary"
                                            >
                                              The system is alert based identifying delivery and consolidation opportunities at the earliest point, visible to all relevant stakeholders.
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItem>
                              </List>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12} sx={{padding:'10px', textAlign:'center'}}>
                        <Button variant="contained" href="/consolidations" color="secondary" sx={isSmallScreen||isxsScreen?{width:'100%'}:{width:'50%'}}>Get Started</Button>
                      </Grid>
              </Grid>
          </Box>
      </>
    )
}