import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
export default function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMessages, setErrorMessages] = useState({
        username: '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    const handleMouseUpPassword = (event) => {
      event.preventDefault();
    };
    const submit = () => {
        let error = false;
        let errorMessages = {
            username: '',
            password: ''
        };
        if(username === ''){
            error = true;
            errorMessages.username = 'Username is required';
        }
        if(password === ''){
            error = true;
            errorMessages.password = 'Password is required';
        }
        if(!error){
            console.log('submit');
        }else{
            setError(error);
            setErrorMessages(errorMessages);
        }
        fetch('https://port-dynamics.com/api-token-auth/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password})
        }).then(response => {
            if(response.status === 400){
                setError(true);
                setErrorMessages({
                    username: 'Invalid username or password',
                    password: 'Invalid username or password'
                });
                throw new Error('Invalid username or password');
            }
            return response.json();
        })
        .then(data => {
            localStorage.setItem('token', data.token);
            // navigate to home
            navigate('/');

        })
        .catch(error => {
            console.log(error);
        });
    }
    return (
       <Grid container spacing={2} sx={{marginTop:'50px'}}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" error={error}>
                        <InputLabel htmlFor="filled-adornment-password">Username</InputLabel>
                        <OutlinedInput
                            id="username"
                            type={'text'}
                            label="Username"
                            fullWidth
                            onChange={(e) => setUsername(e.target.value)}
                            defaultValue={errorMessages.username}
                            onKeyDown={(e) => e.key === 'Enter'?submit():null}
                        />
                        <FormHelperText id="component-error-text">{errorMessages.username}</FormHelperText>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" error={error}>
                        <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                            fullWidth
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter'?submit():null}
                        />
                        <FormHelperText id="component-error-text">{errorMessages.password}</FormHelperText>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                        <Grid item xs={12} md={4} lg={4}>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <Button variant="contained" color="secondary" fullWidth onClick={submit}>Login</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
