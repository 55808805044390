import React, { useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Paper,  Grid} from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';



export default function Parts({token}){
    const [loading, setLoading] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] =  useState(15);
    const [spares, setSpares] = useState([]);
    const[oldSpares, setOldSpares] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [imoFilter, setImoFilter] = useState(undefined);
    const[imoFiltered, setImoFiltered] = useState(undefined);
    const [error, setError] = useState(false);
    const [errorNessage, setErrorMessage] = useState('');
    const [locationFilter, setLocationFilter] = useState(undefined);
    const[locationIDFiltered, setLocationIDFiltered] = useState(undefined);
    const [pageCount, setPageCount] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setLoading(true);
        let url = `https://port-dynamics.com/api/v1/logistics_manager/spares/?page=${newPage+1}`;
        if(locationFilter){
            url = `https://port-dynamics.com/api/v1/logistics_manager/spares/${locationIDFiltered}/?page=${newPage+1}`;
        }
        if(locationFilter && imoFilter && imoFilter.length > 0){
            url = `https://port-dynamics.com/api/v1/logistics_manager/spares/${locationIDFiltered}/imo=${imoFiltered}/?page=${newPage+1}`;
        }
        fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setOldSpares(data.results);
                setNextPage(data.next);
                setLoading(false);  
        })
        .catch(error => {
            console.error(error);
            setLoading(false);  
        });
    };
    
    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };
    useEffect(() => {
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spares/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false); 
                setLocationFilter(false);
        })
        .catch(error => {
            console.error(error);
            setLoading(false);  
        });
    },[token])

    const handelImoFilter = (imo) => {
        setLoading(true);
        setImoFilter(true);
        setImoFiltered(imo);
        if(!locationFilter){
            setError(true);
            setErrorMessage('Please select location or you cannot filter by imo!');
        }
        let url = `https://port-dynamics.com/api/v1/logistics_manager/spares/${locationIDFiltered}/${imo}/`;
        if( imo === ''){
            url = `https://port-dynamics.com/api/v1/logistics_manager/spares/${locationIDFiltered}/`;
        }
        fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false);  
        })
        .catch(error => {
            console.error(error);
            setLoading(false);  
        });
    }
    const handelLocationIdFilter = (locationID) => {
        setLoading(true);
        setLocationFilter(true);
        setLocationIDFiltered(locationID);
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spares/${locationID}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        )
        .then(response => response.json())
        .then(data => {
                setSpares(data.results);
                if(data.results.length === 0){
                    setSpares(oldSpares);
                }
                setNextPage(data.next);
                setPageCount(data.count);
                setLoading(false);  
        })
        .catch(error => {
            setLoading(false);  
        });
    }

    return (
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
            <Grid item xs={12}>
                {error && <Paper style={{ padding: '10px', color: 'red', textAlign: 'center' }}>{errorNessage}</Paper>}
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color='primary' href='/part/add'>Add new part</Button>
            </Grid>
            <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="standard-adornment-amount">Search by location id</InputLabel>
                <Input
                    id="standard-adornment-amount"
                    type="text"
                    startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                    fullWidth
                    onChange={(e) => handelLocationIdFilter(e.target.value)}
                    color="secondary"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="standard-adornment-amount">Search by imo</InputLabel>
                <Input
                    id="standard-adornment-amount"
                    type="text"
                    startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                    fullWidth
                    onChange={(e) => handelImoFilter(e.target.value)}
                    color="secondary"
                />
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                <CircularProgress />
                ) : (
                
                <>
                    <TableContainer component={Paper} sx={{marginTop:'10px' }}>
                        <TablePagination
                            rowsPerPageOptions={[15, 25, 100]}
                            component="div"
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <Table sx={{ minWidth: 650}} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Location ID</TableCell>
                                <TableCell>IMO</TableCell>
                                <TableCell align="right">Description</TableCell>
                                <TableCell align="right">Actual&nbsp;(kg)</TableCell>
                                <TableCell align="right">Currency symbol</TableCell>
                                <TableCell align="right">Purchase cost</TableCell>
                                <TableCell align="right">Received</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {spares.length && spares.map((row) => (
                                    <TableRow
                                    key={row.spare_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    
                                    >

                                        <TableCell component="th" scope="row">
                                            {row.location_id}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.imo}
                                        </TableCell>
                                        <TableCell align="right">{row.description}</TableCell>
                                        <TableCell align="right">{row.act_kg}</TableCell>
                                        <TableCell align="right">{row.currency_symbol}</TableCell>
                                        <TableCell align="right">{row.purchase_cost}</TableCell>
                                        <TableCell align="right">{row.received}</TableCell>
                                        <TableCell align="right">{row.status}</TableCell>
                                        <TableCell align="right"><Button variant="contained" color='primary' href={`/part/${row.spare_id}`}>update</Button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                )}
            </Grid>
    </Grid>
    )
}