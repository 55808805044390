import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {Paper, Typography, Divider} from '@mui/material';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ConstructionIcon from '@mui/icons-material/Construction';
import FilterListIcon from '@mui/icons-material/FilterList';
import UndoIcon from '@mui/icons-material/Undo'
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';




export default function Locations({token}) {
    const [loading, setLoading] = useState(true);
    const [spares, setSpares] = useState([]);
    const [oldSpares, setOldSpares] = useState([]);
    const [arriving, setArriving] = useState([]);
    const [atapproach, setAtApproach] = useState([]);
    const [atfacility, setAtFacility] = useState([]);
    const [inport, setInPort] = useState([]);
    const [locations, setLocations] = useState([]);
    const [error, setError] = useState(null);


    const filterSpares = (id) => {
        return () => {
            if(!id){
                setSpares(oldSpares);
            }else{
                const filterSpares = spares.filter((item) => item.card_colour === id);
                setSpares(filterSpares);
            }
            // Perform your filtering or other logic here
        };
    }
    const filterFleet = (e) => {
        if(!e.target.value){
            setSpares(oldSpares);
            return
        }
        const filteredSpares = spares.filter(spare => spare.spare_id.toString().includes(e.target.value));
        if(!filteredSpares.length){
            setSpares([oldSpares]);
    
        }else{
            setSpares(filteredSpares);
        }
    }

    useEffect(() => {
        fetch('https://port-dynamics.com/api/v1/logistics_manager/locations/?d=y', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_id}
            })

            setLocations(newData);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [token]);
    const makeAPiCall = (event, newVal) => {
        if(!newVal){
            setError(null);
            setSpares([]);
            setArriving([]);
            setAtApproach([]);
            setAtFacility([]);
            setInPort([]);
            return;
        }
        fetch(`https://port-dynamics.com/api/v1/logistics_manager/location/?location_id=${newVal.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(response.ok){
                return response.json();
            }else{
                throw new Error(response.statusText);
            }
        })
        .then(data => {
            setError(null);
            setSpares(data.spares);
            setOldSpares(data.spares);
            setArriving(data.arriving);
            setAtApproach(data.at_approach);
            setAtFacility(data.at_facility);
            setInPort(data.in_port);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.message);
            setSpares([])
            setOldSpares([])
            setArriving([])
            setAtFacility([])
            setAtApproach([])
            setInPort([])
        });
    }
    return (
        loading ? (
            <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
              <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
              </Grid>
            </Grid>
        ) :(
                <Grid container spacing={2} style={{marginTop:'5px'}}>
                     <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="standard-adornment-amount">Search by spare id</InputLabel>
                        <Input
                            id="standard-adornment-amount"
                            type="text"
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                            fullWidth
                            onChange={filterFleet}
                            color="secondary"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop:'5px'}}>
                        <Stack direction={{ xs: "column", sm: "column", lg:"row"}} spacing={1}>
                            <Chip 
                                onClick={filterSpares("#B5E6A2")} label="Delivery opportunites at hub" size="medium" sx={{backgroundColor:"#B5E6A2"}} icon={<FilterListIcon />} variant="outlined"
                            />
                            <Chip onClick={filterSpares("#FFCC00")} label="Delivery opportunites at other ports" size="medium" sx={{backgroundColor:"#FFCC00"}}  icon={<FilterListIcon />} variant="outlined"
                            />
                            <Chip  onClick={filterSpares("#CCECFF")} label="No immediate delivery opportunites" size="medium" sx={{backgroundColor:"#CCECFF"}} icon={<FilterListIcon/>} variant="outlined"
                            />
                            <Chip  onClick={filterSpares(undefined)} size="medium"  icon={<UndoIcon/>} variant="outlined"
                            />
                        </Stack>
                    </Grid>
                    <Divider/>
                    <Grid item xs={12} sm={12} style={{marginTop:'10px'}}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={locations}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option.label}
                            onChange={makeAPiCall}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Select Location"
                                    variant="outlined"
                                    fullWidth
                                />
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                            style={{width:'100%'}}
                        />
                    </Grid>

                    {error?
                        <Grid item xs={12} sm={12} lg={12}>
                            <Alert style={{marginTop:'50px'}} severity="error"><Typography component="h1" variant="h1">An error occured: {error}</Typography></Alert>
                        </Grid>
                        :null
                    }
                    {spares.length?(
                        <Grid item xs={12} sm={12} lg={4}>
                            <Typography variant="h1" component="h1">Your parts</Typography>
                            {loading? (<CircularProgress/>): (null)}
                            {spares.map((item, index) => (
                                <Paper
                                    key={index}
                                    elevation={3}
                                    style={{ padding: '20px', margin: '20px 0', backgroundColor: '#fff', boxShadow: `0 0 15px ${item.card_colour}` }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <ConstructionIcon fontSize="small" color="primary" />
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                    Spare id: {item.spare_id}
                                            </Typography>
                                            <Typography variant="body2">
                                                    Part Description: {item.description}
                                            </Typography>
                                            <Typography variant="body2" >
                                                    Status: {item.status}
                                            </Typography>
                                            <Button href={`/part/${item.spare_id}`} variant="contained" color="primary" size="small">
                                                Click for more info....
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <DirectionsBoatIcon fontSize="small" color="primary" />
                                            <Typography variant="body2" component="h2" fontWeight={'bold'}>
                                            Shipname: {`${item.ship_name}(${item.imo})`}
                                            </Typography>
                                            <Typography variant="body2" component="h2" >
                                            ATA eta: {item.ata_eta}
                                            </Typography>
                                            <Typography variant="body2" component="h2" >
                                            Destination: {item.port_name}
                                            </Typography>
                                            <Button href={`/schedule/${item.imo}`} variant="contained" color="primary" size="small">
                                                View Schedule
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </Grid> 
                    ):null}
                        <Grid item xs={12} sm={12} lg={8}>
                            {arriving.length?<Typography variant="h1" component="h1">Arriving</Typography>:null}
                            {arriving.length > 0 && arriving.map((item, index) => (
                                        <Paper
                                        key={index}
                                        elevation={3}
                                        style={{ padding: '20px', margin: '20px 0', backgroundColor: '#fff' }}
                                    >
                                        <Grid container spacing={2} style={{marginTop:'5px'}}>
                                            <Grid item xs={12} sm={12} lg={4}>
                                                <DirectionsBoatIcon fontSize="small" color="primary" />
                                                <Typography variant="body2" component="h2">
                                                Shipname: {item.ship_name}
                                                </Typography>
                                                <Typography variant="body2">
                                                IMO: {item.imo}
                                                </Typography>
                                                <Typography variant="body2">
                                                DWT: {item.dwt}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={8}>
                                                <LocationOnIcon fontSize="small" color="primary" />
                                                <Typography variant="body2" component="h2" >
                                                AIS eta: {item.ais_eta}
                                                </Typography>
                                                <Typography variant="body2" component="h2" >
                                                Speed: {item.speed}
                                                </Typography>
                                                <Typography variant="body2" component="h2" >
                                                Status: {item.status}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            )

                            )}
                            {inport.length?<Typography variant="h1" component="h1">In Port</Typography>:null}
                            {inport.length >0  && inport.map((item, index) => (
                                        <Paper
                                        key={index}
                                        elevation={3}
                                        style={{ padding: '20px', margin: '20px 0',  backgroundColor: '#fff' }}
                                    >
                                        <Grid container spacing={2} style={{marginTop:'5px'}}>
                                            <Grid item xs={12} sm={12} lg={12}>
                                                <DirectionsBoatIcon fontSize="small" color="primary" />
                                                <Typography variant="body2" component="h2">
                                                Shipname: {item.ship_name}
                                                </Typography>
                                                <Typography variant="body2">
                                                IMO: {item.imo}
                                                </Typography>
                                                <Typography variant="body2">
                                                DWT: {item.dwt}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            )

                            )}
                            {atfacility.length?<Typography variant="h1" component="h1">At Facility</Typography>:null}
                            {atfacility.length >0  && atfacility.map((item, index) => (
                                        <Paper
                                        key={index}
                                        elevation={3}
                                        style={{ padding: '20px', margin: '20px 0',  backgroundColor: '#fff' }}
                                    >
                                        <Grid container spacing={2} style={{marginTop:'5px'}}>
                                            <Grid item xs={12} sm={12} lg={12}>
                                                <DirectionsBoatIcon fontSize="small" color="primary" />
                                                <Typography variant="body2">
                                                Facility: {item.facility_name}
                                                </Typography>
                                                <Typography variant="body2" component="h2">
                                                Shipname: {item.ship_name}
                                                </Typography>
                                                <Typography variant="body2">
                                                IMO: {item.imo}
                                                </Typography>
                                                <Typography variant="body2">
                                                DWT: {item.dwt}
                                                </Typography>
                                                <Typography variant="body2">
                                                Arrived: {item.arrived}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            )

                            )}
                            {atapproach.length?<Typography variant="h1" component="h1">At Approaches</Typography>:null}
                            {atapproach.length >0  && atapproach.map((item, index) => (
                                        <Paper
                                        key={index}
                                        elevation={3}
                                        style={{ padding: '20px', margin: '20px 0',  backgroundColor: '#fff' }}
                                    >
                                        <Grid container spacing={2} style={{marginTop:'5px'}}>
                                            <Grid item xs={12} sm={12} lg={12}>
                                                <DirectionsBoatIcon fontSize="small" color="primary" />
                                                <Typography variant="body2" component="h2">
                                                Shipname: {item.ship_name}
                                                </Typography>
                                                <Typography variant="body2">
                                                IMO: {item.imo}
                                                </Typography>
                                                <Typography variant="body2">
                                                DWT: {item.dwt}
                                                </Typography>
                                                <Typography variant="body2" component="h2" >
                                                ATA: {item.ata}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            )

                            )}

                        </Grid>
                </Grid>
        )
            
    )
}
